<template>
	<div class="user">
		<scroll-view class="user-scroll">
			<div class="head">
				<div class="head-top">
					<div class="head-top-left">
						<div class="left-picture"><img :src="UserInfo.avatar" alt="" /></div>
						<div class="head-top-conner" v-if="UserInfo.role_type != 1">
							<div class="conner-title">{{ UserInfo.nickname }}<span v-if="user.role_type == 3 && UserInfo.member_level != ''">职衔：{{UserInfo.member_level}}</span></div>
							<div class="conner-text">角色：{{ user.role_type_text }}</div>
							<div class="conner-text">区域：{{ user.district_name }}</div>
							<div class="conner-text">队伍：{{ user.department_name }}</div>
						</div>
						<div v-else class="head-top-title" @click="$router.push({ name: 'Register' })">点击注册</div>
					</div>
					<div class="head-top-right">
						<img src="@/assets/img/medal1.png" alt="" v-if="user.user_level == 1" />
						<img src="@/assets/img/medal2.png" alt="" v-if="user.user_level == 2" />
						<img src="@/assets/img/medal3.png" alt="" v-if="user.user_level == 3" />
						<img src="@/assets/img/medal10.png" alt="" v-if="user.user_level == 4" />
						<img src="@/assets/img/medal4.png" alt="" v-if="user.user_level == 5" />
						<img src="@/assets/img/medal5.png" alt="" v-if="user.user_level == 6" />
						<img src="@/assets/img/medal6.png" alt="" v-if="user.user_level == 7" />
						<img src="@/assets/img/medal7.png" alt="" v-if="user.user_level == 8" />
						<img src="@/assets/img/medal8.png" alt="" v-if="user.user_level == 9" />
						<img src="@/assets/img/medal9.png" alt="" v-if="user.user_level == 10" />
					</div>
				</div>
				
				<div class="head-middle">
					<div class="head-middle-item">
						<div class="item-title">
							{{ user.service_time == '' ? 0 : user.service_time }}
							<span>小时</span>
						</div>
						<div class="item-text">服务时长</div>
					</div>
					<div class="head-middle-item">
						<div class="item-title">{{ user.activities }}</div>
						<div class="item-text">我的活动</div>
					</div>
					<div class="head-middle-item">
						<div class="item-title">{{ user.favorites }}</div>
						<div class="item-text">我的收藏</div>
					</div>
				</div>

				<div class="head-bottom" @click="$router.push({ name: 'UserLevel' })">
					<div class="head-bottom-left">
						<div class="head-bottom-left-title">
							{{ user.user_level_text }}
						</div>
						<div class="head-bottom-left-text">
							爱心值：{{ user.love_value }}
						</div>
					</div>
					<div class="head-bottom-right">
						<img src="@/assets/img/us2.png" alt="" />
					</div>
				</div>
			</div>
			<div class="body">
				<div class="body-content">
					<div class="body-content-title">常用功能</div>
					<div class="body-content-column">
						<div class="body-column-item" @click="$router.push({ name: 'LevelDescription' })"
							v-if="user.role_type == 3">
							<div class="body-item-img img-top">
								<img src="@/assets/img/u_icon2.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts2.png" alt="" v-else>
							</div>
							<div class="body-item-text">义警证件</div>
						</div>
						<div class="body-column-item" @click="$router.push({ name: 'UserInfo', params:{id: UserInfo.id}})"
							v-if="UserInfo.role_type != 1">
							<div class="body-item-img img-top">
								<img src="@/assets/img/u_icon1.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts1.png" alt="" v-else>
							</div>
							<div class="body-item-text">爱心足迹</div>
						</div>
						<div class="body-column-item" @click="$router.push({ name: 'Contribute' })"
							v-if="UserInfo.role_type != 1">
							<div class="body-item-img img-top">
								<img src="@/assets/img/u_icon4.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts4.png" alt="" v-else>
							</div>
							<div class="body-item-text">会费缴纳</div>
						</div>
						<div class="body-column-item" @click="$router.push({ name: 'ModifyInfo' })"
							v-if="UserInfo.role_type != 1">
							<div class="body-item-img img-top">
								<img src="@/assets/img/u_icon5.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts5.png" alt="" v-else>
							</div>
							<div class="body-item-text">修改资料</div>
						</div>
						<div class="body-column-item" @click="$router.push({ name: 'UserCollect' })">
							<div class="body-item-img img-top">
								<img src="@/assets/img/u_icon3.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts3.png" alt="" v-else>
							</div>
							<div class="body-item-text">我的收藏</div>
						</div>
					</div>
				</div>
				<div class="body-content">
					<div class="body-content-title">活动&积分</div>
					<div class="body-content-column">
						<div class="body-column-item" @click="$router.push({ name: 'UserEnrollShaky' })">
							<div class="body-item-img img-middle">
								<img src="@/assets/img/u_icon6.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts6.png" alt="" v-else>
							</div>
							<div class="body-item-text">已报活动</div>
						</div>
						<div class="body-column-item" @click="$router.push({ name: 'UserFinishShaky' })">
							<div class="body-item-img img-middle">
								<img src="@/assets/img/u_icon7.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts7.png" alt="" v-else>
							</div>
							<div class="body-item-text">完成活动</div>
						</div>
						<!-- <div class="body-column-item" @click="$router.push({ name: 'Additional' })">
							<div class="body-item-img img-middle">
								<img src="@/assets/img/u_icon20.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts20.png" alt="" v-else>
							</div>
							<div class="body-item-text">补卡申请</div>
						</div> -->
						<div class="body-column-item" @click="$router.push({ name: 'UserExchange' })">
							<div class="body-item-img img-middle">
								<img src="@/assets/img/u_icon9.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts9.png" alt="" v-else>
							</div>
							<div class="body-item-text">兑换记录</div>
						</div>
						<!-- <div class="body-column-item">
							<div class="body-item-img img-middle">
								<img src="@/assets/img/u_icon10.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts10.png" alt="" v-else>
							</div>
							<div class="body-item-text">爱心明细</div>
						</div> -->
					</div>
				</div>
				<div class="body-content">
					<div class="body-content-title">订单记录</div>
					<div class="body-content-column">
						<div class="body-column-item"
							@click="$router.push({ name: 'ConsumeList', params: { type: 2 }})">
							<div class="body-item-img img-middle">
								<img src="@/assets/img/u_icon11.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts11.png" alt="" v-else>
							</div>
							<div class="body-item-text">待自提</div>
						</div>
						<div class="body-column-item"
							@click="$router.push({ name: 'ConsumeList', params: { type: 3 }})">

							<div class="body-item-img img-middle">
								<!-- <van-badge :content="5"> -->
									<img src="@/assets/img/u_icon12.png" alt="" v-if="districtType() == 1">
									<img src="@/assets/img/u_icon_ts12.png" alt="" v-else>
								<!-- </van-badge> -->
							</div>

							<div class="body-item-text">待发货</div>
						</div>
						<!-- <div class="body-column-item" @click="$router.push({ name: 'ConsumeList' })">
							<div class="body-item-img img-middle">
								<img src="@/assets/img/u_icon13.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts13.png" alt="" v-else>
							</div>
							<div class="body-item-text">已发货</div>
						</div> -->
						<div class="body-column-item"
							@click="$router.push({ name: 'ConsumeList', params: { type: 5 } })">
							<div class="body-item-img img-middle">
								<img src="@/assets/img/u_icon14.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts14.png" alt="" v-else>
							</div>
							<div class="body-item-text">已完成</div>
						</div>
						<div class="body-column-item"
							@click="$router.push({ name: 'ConsumeList', params: { type: 0 } })">
							<div class="body-item-img img-middle">
								<img src="@/assets/img/u_icon15.png" alt="" v-if="districtType() == 1">
								<img src="@/assets/img/u_icon_ts15.png" alt="" v-else>
							</div>
							<div class="body-item-text">全部订单</div>
						</div>
					</div>
				</div>
				<div class="body-content">
					<div class="body-content-title">更多功能</div>
					<div class="body-content-column">
						<div class="body-column-item" @click="$router.push({ name: 'ApplyVigilante' })" v-if="user.role_type == 2">
							<div class="body-item-img img-footer">
								<img src="@/assets/img/u_icon16.png" alt="">
							</div>
							<div class="body-item-text">申请义警</div>
						</div>
						<div class="body-column-item" @click="$router.push({ name: 'AuditList' })" v-if="UserInfo.duty_level > 1 && UserInfo.duty_level < 5">
							<div class="body-item-img img-footer">
								<van-badge :content="auditTotal" v-if="auditTotal">
									<img src="@/assets/img/u_icon21.png" alt="">
								</van-badge>
								<img src="@/assets/img/u_icon21.png" alt="" v-else>
							</div>
							<div class="body-item-text">审核义警</div>
						</div>
						<div class="body-column-item" @click="$router.push({ name: 'XzAuditList' })" v-if="UserInfo.duty_level == 4">
							<div class="body-item-img img-footer">
								<van-badge :content="xzAuditTotal" v-if="xzAuditTotal">
									<img src="@/assets/img/u_icon21.png" alt="">
								</van-badge>
								<img src="@/assets/img/u_icon21.png" alt="" v-else>
							</div>
							<div class="body-item-text">审核义警（信州）</div>
						</div>
						<div class="body-column-item" @click="$router.push({ name: 'AuditDemotion', params:{id: UserInfo.district_id, dutyLevel: UserInfo.duty_level} })" v-if="UserInfo.duty_level > 2">
							<div class="body-item-img img-footer">
								<img src="@/assets/img/u_icon123.png" alt="">
							</div>
							<div class="body-item-text">义警降级</div>
						</div>
						<div class="body-column-item" @click="$router.push({ name: 'SuggestList'})" v-if="UserInfo.duty_level > 2">
							<div class="body-item-img img-footer">
								<van-badge :content="suggestTotal.no_reply" v-if="suggestTotal.no_reply != 0">
									<img src="@/assets/img/u_icon17.png" alt="">
								</van-badge>
								<img src="@/assets/img/u_icon17.png" alt="" v-else>
							</div>
							<div class="body-item-text">意见建议</div>
						</div>
						<div class="body-column-item" @click="$router.push({ name: 'HelpCenter' })">
							<div class="body-item-img img-footer">
								<img src="@/assets/img/u_icon18.png" alt="">
							</div>
							<div class="body-item-text">关于我们</div>
						</div>
						
						<!-- <div class="body-column-item" @click="$router.push({ name: 'ActivityAudit' })" v-if="UserInfo.duty_level > 3">
							<div class="body-item-img img-footer">
								<van-badge :content="activityAuditTotal" v-if="activityAuditTotal">
									<img src="@/assets/img/u_icon128.png" alt="">
								</van-badge>
								<img src="@/assets/img/u_icon128.png" alt="" v-else>
							</div>
							<div class="body-item-text">特殊任务</div>
						</div> -->
					</div>
				</div>
			</div>
		</scroll-view>
		<w-tabbar></w-tabbar>
	</div>
</template>

<script>
	import WTabbar from '@/components/Tabbar';
	import ScrollView from '@/components/ScrollView';
	import scrollMiXin from '@/mixins/scroll';
	import UserApi from '@/api/user';
	import CompanyApi from '@/api/company';
	import VantVendor from '@/vendor/vant';
	
	const scroll = scrollMiXin(['.user-scroll']);

	export default {
		inject: ['className', 'districtType', 'themeColor'],
		name: 'User',
		mixins: [scroll],
		data() {
			return {
				user: '',
				UserInfo: '',
				auditTotal: '',
				suggestTotal: '',
				activityAuditTotal: '',
				xzAuditTotal: ''
			}
		},
		created() {
			this.$store.commit('keep/setKeep', 'User')
			this.getUserHome()
			this.getUserInfo()
			this.getAudiTtotal()
			this.getSuggestTotal()
			// this.getActivityAuditUnread()
		},
		activated() {
			this.getUserHome()
			this.getUserInfo()
			this.getAudiTtotal()
			this.getSuggestTotal()
			// this.getActivityAuditUnread()
			this.$store.commit('keep/removeKeep', 'AuditDemotion')
			this.$store.commit('keep/removeKeep', 'AuditList')
			this.$store.commit('keep/removeKeep', 'XzAuditList')
			this.$store.commit('keep/removeKeep', 'ActivityAudit')
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo
			}
		},
		methods: {
			getXzAuditTotal() {
				UserApi.xzAuditTotal()
					.then(result => {
						this.xzAuditTotal = result.data.total;
					})
					.catch(error => {
						console.log(error);
					});
			},
			getUserInfo() {
				UserApi.getUserInfo().then(result => {
					this.UserInfo = result.data;
					if(this.UserInfo.duty_level == 4){
						this.getXzAuditTotal()
					}
				});
			},
			getUserHome() {
				UserApi.getUserCenter()
					.then(result => {
						this.user = result.data;
					})
					.catch(error => {
						console.log(error);
					});
			},
			getAudiTtotal() {
				UserApi.audiTtotal()
					.then(result => {
						this.auditTotal = result.data.total;
					})
					.catch(error => {
						console.log(error);
					});
			},
			getSuggestTotal() {
				CompanyApi.suggestTotal()
					.then(result => {
						this.suggestTotal = result.data
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			getActivityAuditUnread() {
				UserApi.activityAuditUnread()
					.then(result => {
						this.activityAuditTotal = result.data.total;
					})
					.catch(error => {
						console.log(error);
					});
			},
		},
		components: {
			WTabbar,
			ScrollView
		}
	};
</script>

<style lang="less" scoped>
	.user {
		min-height: calc(100vh - 50px);

		.user-scroll {
			height: calc(100vh - 50px);
		}

		.head {
			width: 100%;
			height: 259px;
			padding: 0 10px;
			background-size: cover;
			box-sizing: border-box;
			position: relative;

			.head-top {
				padding: 20px 10px 30px 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				box-sizing: border-box;

				.head-top-left {
					display: flex;
					justify-content: flex-start;
					position: relative;

					.left-picture {
						width: 70px;
						min-width: 70px;
						height: 70px;
						margin-top: 10px;

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
						}
					}

					.left-picture-ring {
						position: absolute;
						top: -5px;
						width: 72px;
						height: 72px;
						background-size: cover;
					}

					.head-top-title {
						width: 100%;
						font-size: 26px;
						text-align: center;
						color: #fff;
						margin-left: 20px;
						margin-top: 23px;
					}

					.head-top-conner {
						padding-left: 10px;

						.conner-title {
							width: 170px;
							min-width: 170px;
							font-weight: bold;
							font-size: 18px;
							line-height: 26px;
							margin-bottom: 3px;
							color: #fff;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							
							span {
								color: #FFF;
								font-size: 12px;
								margin-left: 10px;
							}
						}

						.conner-text {
							font-size: 14px;
							line-height: 20px;
							color: #fff;
						}
					}
				}

				.head-top-right {
					width: 60px;
					min-width: 60px;
					height: 60px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			.head-middle {
				padding: 0 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.head-middle-item {
					text-align: center;

					.item-title {
						font-size: 22px;
						line-height: 26px;
						color: #fff;

						span {
							font-size: 12px;
						}
					}

					.item-text {
						font-size: 14px;
						line-height: 24px;
						color: #fff;
					}
				}
			}

			.head-bottom {
				position: absolute;
				width: 343px;
				height: 61px;
				left: 16px;
				bottom: -10px;
				padding: 10px 23px 20px 11px;
				background-image: url('../../assets/img/us1.png');
				background-size: cover;
				display: flex;
				justify-content: space-between;
				align-items: center;
				box-sizing: border-box;

				.head-bottom-left {
					display: flex;
					align-items: center;

					.head-bottom-left-title {
						font-size: 15px;
						color: #FFF;
					}

					.head-bottom-left-text {
						font-size: 13px;
						margin-left: 17px;
						color: #FFF;
					}
				}

				.head-bottom-right {
					width: 16px;
					height: 16px;
					min-width: 16px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}

		.conner {
			padding: 18px 10px 12px;

			.middle {
				background: #fff;
				padding: 15px 23px;
				border-radius: 6px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.middle-item {
					.middle-item-top {
						margin-left: 5px;
						width: 50px;
						height: 50px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.middle-item-text {
						font-size: 14px;
						line-height: 16px;
						color: #333;
						margin-top: 5px;
					}
				}
			}

			.footer {
				background: #ffffff;
				padding: 0 20px;
				border-radius: 6px;
				margin-top: 10px;

				.footer-item {
					padding: 12px 0;
					border-bottom: 0.5px solid #f2f2f2;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.footer-item-left {
						display: flex;
						justify-content: flex-start;
						align-items: center;

						.left-picture {
							width: 24px;
							height: 24px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.left-title {
							font-size: 16px;
							line-height: 26px;
							color: #333;
							margin-left: 4px;
						}
					}
				}

				.footer-item:last-child {
					border: 0;
				}
			}
		}

		.body {
			padding: 18px 16px 12px;
			box-sizing: border-box;

			.body-content {
				border-radius: 8px;
				background: #FFF;
				margin-bottom: 12px;
				box-sizing: border-box;

				.body-content-title {
					padding: 10px 0;
					border-bottom: 1px solid #F2F2F2;
					box-sizing: border-box;
					color: #333;
					text-align: justify;
					font-size: 17px;
					font-weight: bold;
					line-height: 24px;
					margin: 0 8px;
				}

				.body-content-column {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					margin: 0 4px;

					.body-column-item {
						padding: 16px 0;
						width: 58px;
						min-width: 58px;
						// margin-right: 9px;
						margin: 0 4px;
						box-sizing: border-box;

						.body-item-img {
							margin: 0 auto;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}

							&.img-top {
								width: 32px;
								height: 32px;
								min-width: 32px;
							}

							&.img-middle {
								width: 28px;
								height: 28px;
								min-width: 28px;
							}

							&.img-footer {
								width: 24px;
								height: 24px;
								min-width: 24px;
							}
						}

						.body-item-text {
							color: #222;
							text-align: center;
							font-size: 14px;
							line-height: 16px;
							margin-top: 8px;
						}
					}
				}
			}
		}
	}

	.user.city {
		.head {
			background-image: url('../../assets/img/u_icon119.png');
		}
	}

	.user.district {
		.head {
			background-image: url('../../assets/img/u_icon120.png');
		}
	}
</style>